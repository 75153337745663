import { createBrowserRouter, Navigate } from 'react-router-dom'
import { PublicFormLayout, PublicLayout } from './layout'
import {
  AboutPage,
  ApplicationPage,
  HomePage,
  PricingPage,
  FrontendPage,
  BackendPage,
  WebdevPage,
  WebinarPage,
  DataAnalysisPage,
  ProductDesignPage,
  LiveClassesPage,
} from './pages'
import { AutomationQaPage } from './pages/AutomationQaPage'
import { ManualQaPage } from './pages/ManualQaPage'
import { BlockchainPage } from './pages/BlockchainPage'
import { DevOpsPage } from './pages/DevOpsPage'
import { FullstackPage } from './pages/FullstackPage'

const router = createBrowserRouter([
  {
    path: '/',
    element: <PublicLayout data-testid="public-layout" />,
    children: [
      {
        path: '',
        element: <HomePage />,
      },
      {
        path: '/about',
        element: <AboutPage />,
      },
      {
        path: '/pricing',
        element: <PricingPage />,
      },
      {
        path: '/engineering/frontend',
        element: <FrontendPage />,
      },
      {
        path: '/engineering/backend',
        element: <BackendPage />,
      },
      {
        path: '/engineering/web-development',
        element: <WebdevPage />,
      },
      {
        path: '/engineering/manual-qa',
        element: <ManualQaPage />,
      },
      {
        path: '/engineering/automation-qa',
        element: <AutomationQaPage />,
      },
      {
        path: '/engineering/blockchain',
        element: <BlockchainPage />,
      },
      {
        path: '/engineering/fullstack',
        element: <FullstackPage />,
      },
      {
        path: '/engineering/devops',
        element: <DevOpsPage />,
      },
      {
        path: '/data/data-analysis',
        element: <DataAnalysisPage />,
      },
      {
        path: '/product/product-design',
        element: <ProductDesignPage />,
      },
      {
        path: 'webinar',
        element: <WebinarPage />,
      },
      {
        path: 'classes',
        element: <LiveClassesPage />,
      },
      {
        path: '*',
        element: <Navigate to="/" replace />,
      },
    ],
  },
  {
    path: '',
    element: <PublicFormLayout data-testid="public-auth-layout" />,
    children: [
      {
        path: 'register',
        element: <ApplicationPage />,
      },
    ],
  },
])

export default router
