import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react'
import React from 'react'
import aboutImage from '../../assets/about-image.jpg'
import { APPLICATION_MAX_WIDTH } from '../../constants'

export const WhoWeAre = (): React.ReactElement => {
  return (
    <Flex
      gap="5"
      maxWidth={APPLICATION_MAX_WIDTH}
      width={{ base: '100%', lg: '85%', xl: '70%' }}
      paddingY="10"
      paddingX="5"
      marginX="auto"
      flexDirection={{ base: 'column', md: 'row' }}
      justifyContent="center"
      alignItems="center"
      textAlign={{ base: 'center', md: 'left' }}
    >
      <Box width={{ base: '100%', md: '55%' }}>
        <Heading as="h2" fontWeight="bold" marginBottom="5">
          Who We Are?
        </Heading>
        <Text
          as="p"
          fontSize={{base: 'md', md: 'lg'}}
          mb="3"
          data-testid="who-we-are-1"
          color="gray.800"
        >
          At Dot Campus, our unwavering dedication revolves around the mission
          of cultivating and empowering the inherent talents of young
          individuals across the African continent.
        </Text>
        <Text
          as="p"
          fontSize={{base: 'md', md: 'lg'}}
          mb="3"
          data-testid="who-we-are-2"
          color="gray.800"
        >
          Our aim is to provide them with the necessary knowledge, skills, and
          opportunities to not only enter but also excel in the dynamic and
          ever-evolving field of technology.
        </Text>
        <Text
          as="p"
          fontSize={{base: 'md', md: 'lg'}}
          mb="3"
          data-testid="who-we-are-3"
          color="gray.800"
        >
          With our guidance, mentorship and learning support, we are committed
          to enabling these promising talents to embark on a trajectory of
          success and innovation, propelling them to new heights in the world of
          technology. 🚀
        </Text>
      </Box>
      <Box width={{ base: '100%', md: '45%' }}>
        <Image
          src={aboutImage}
          alt="about us (image by mikhail nilov)"
          borderTopLeftRadius="100px"
          borderBottomRightRadius="100px"
          width={{ base: '100%', xl: 'auto' }}
          marginX="auto"
        />
      </Box>
    </Flex>
  )
}
