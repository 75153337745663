import { Grid } from '@chakra-ui/react'

export const DisplayHTML = ({ data }: { data: string }): React.ReactElement => {
  return (
    <Grid
      gap={2}
      sx={{
        'p': {
          fontSize: {
            base: '14px',
            md: '16px',
          },
        },
        strong: {
          color: 'blue.800',
        },
      }}
      dangerouslySetInnerHTML={{ __html: data }}
    />
  )
}
