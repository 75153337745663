import React from 'react'
import {
  AdmissionRequirement,
  Delivery,
  Highlight,
  Overview,
  ProgramHero,
  ProgrammeFooter,
} from '../components/programmes'
import { courses } from '../constants/courses'
import { Metadata, ShortCoursesSection } from '../components'
import CourseCurriculum from '../components/programmes/CourseCurriculum'
import {PricingPage} from './PricingPage'

export const FullstackPage: React.FC = () => {
  const course = courses.fullstack
  return (
    <>
      <Metadata
        title="Learn Fullstack Engineering | Dot Campus"
        description="Learn Fullstack Engineering at Dot Campus"
      />
      <ProgramHero programChoice={course} />
      <Highlight />
      <Overview programChoice={course} />
      <AdmissionRequirement />
      <CourseCurriculum data={course.curriculum} />
      <Delivery />
      <PricingPage isFullstack={true} />
      <ProgrammeFooter programChoice={course} />
      <ShortCoursesSection />
    </>
  )
}
