import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import React from 'react'
import { APPLICATION_MAX_WIDTH } from '../../../constants'
import { mentors } from '../../../constants/mentors'
import { MentorCard } from './MentorCard'
import { Link } from '../Link'

export const Mentors = ({
  noOfMentors,
}: {
  noOfMentors: number
}): React.ReactElement => {
  return (
    <Box
      id="mentors"
      as="section"
      maxWidth={APPLICATION_MAX_WIDTH}
      marginX="auto"
      padding="7"
      textAlign="center"
      marginTop="10"
      data-testid="mentors-section"
    >
      <Heading as="h2">Meet Our Mentors</Heading>
      <Text color="gray.700">
        Meet some of our experienced mentors and some of the best in the
        industry.
      </Text>
      <Flex
        wrap="wrap"
        gap="5"
        mt="10"
        width="100%"
        marginX="auto"
        justifyContent="center"
      >
        {mentors.slice(0, noOfMentors).map(mentor => (
          <MentorCard
            key={mentor.name}
            name={mentor.name}
            image={mentor.image}
            description={mentor.description}
            linkedin={mentor.linkedin}
            twitter={mentor.twitter}
            github={mentor.github}
            website={mentor.website}
          />
        ))}
      </Flex>
      {mentors.length !== noOfMentors && (
        <Box
          paddingTop="10"
          marginBottom="10"
          textDecoration="underline"
          textUnderlineOffset="2"
          mx="auto"
        >
          <Link fontSize="lg" to="/about#mentors">
            View all mentors
          </Link>
        </Box>
      )}
    </Box>
  )
}
