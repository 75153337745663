import React, { useState } from 'react'
import { Box, Heading, SimpleGrid } from '@chakra-ui/react'
import { ProfileCard } from './common/ProfileCard'
import { APPLICATION_MAX_WIDTH, Webinar } from '../../constants'

export const WebinarPanelists = ({
  currentWebinar,
  isPastDate,
}: {
  currentWebinar: Webinar
  isPastDate: boolean
}): React.ReactElement => {
  const [showFullAbout, setShowFullAbout] = useState<boolean>(false)

  const toggleAbout = (): void => {
    setShowFullAbout(!showFullAbout)
  }

  const panelists = currentWebinar.panelists
  if (!panelists || isPastDate) {
    return <></>
  }
  return (
    <Box
      maxWidth={APPLICATION_MAX_WIDTH}
      width="100%"
      mt={{ base: '0', md: '-120px' }}
      mx="auto"
      padding={{ base: '5', md: '10' }}
      data-testid="webinar-panelists"
    >
      <Heading as="h4" size="lg" mb={4}>
        Webinar Panelists and Speakers
      </Heading>
      <SimpleGrid minChildWidth="300px" spacing={5}>
        {panelists?.map(panelist => (
          <ProfileCard
            key={panelist.name}
            profile={panelist}
            showFullAbout={showFullAbout}
            toggleAbout={toggleAbout}
          />
        ))}
      </SimpleGrid>
    </Box>
  )
}
