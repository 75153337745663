import { Box, Heading } from '@chakra-ui/react'
import React from 'react'
import { APPLICATION_MAX_WIDTH } from '../../constants'
import TabsComp from '../common/TabsComp'
import { Curriculum } from '../../types'

const CourseCurriculum = ({
  data,
}: {
  data: Curriculum
}): React.ReactElement => {
  return (
    <Box
      maxWidth={APPLICATION_MAX_WIDTH}
      width={{ base: '90%' }}
      mx={'auto'}
      my={'50px'}
      data-testid="curriculum"
    >
      <Heading as="h2" mb="5">
        Curriculum
      </Heading>
      <TabsComp data={data} />
    </Box>
  )
}

export default CourseCurriculum
