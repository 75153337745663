import React from 'react'
import {
  AdmissionRequirement,
  Delivery,
  Highlight,
  Overview,
  PricingSection,
  ProgramHero,
  ProgrammeFooter,
} from '../components/programmes'
import { courses } from '../constants/courses'
import { Metadata, ShortCoursesSection } from '../components'
import CourseCurriculum from '../components/programmes/CourseCurriculum'

export const DevOpsPage: React.FC = () => {
  const course = courses.devOps
  return (
    <>
      <Metadata
        title="Learn DevOps/Cloud Engineering | Dot Campus"
        description="Learn DevOps/Cloud Engineering at Dot Campus"
      />
      <ProgramHero programChoice={course} />
      <Highlight />
      <Overview programChoice={course} />
      <AdmissionRequirement />
      <CourseCurriculum data={course.curriculum} />
      <Delivery />
      <PricingSection />
      <ProgrammeFooter programChoice={course} />
      <ShortCoursesSection />
    </>
  )
}
