import React from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Divider,
  Flex,
  Heading,
  Text,
} from '@chakra-ui/react'
import { AccordionType } from '../../types'
import { LockIcon } from '@chakra-ui/icons'

export const CourseAccordion = ({
  data,
}: {
  data: AccordionType
}): React.ReactElement => {
  return (
    <Accordion data-testid="course-accordion" pt={3} allowToggle>
      {data.map(item => (
        <AccordionItem key={item.heading}>
          <Heading pt={3} as="h3" fontWeight="semibold">
            <AccordionButton px={1}>
              <Text
                flex="1"
                textAlign="left"
                color="inherit"
                fontWeight="semibold"
                fontSize={{ base: '14px', md: '16px' }}
              >
                {item.heading}
              </Text>
              <AccordionIcon />
            </AccordionButton>
          </Heading>
          <AccordionPanel pl={1}>
            {item.contents.map((content: string) => (
              <Flex pt={3} key={content} alignItems="center" gap="2">
                <LockIcon boxSize={3} color="gray.500" />
                <Text fontSize={{ base: '14px', md: '16px' }}>{content}</Text>
              </Flex>
            ))}
          </AccordionPanel>
          <Divider mt={3} color={'gray.200'} border={'1px solid'} />
        </AccordionItem>
      ))}
    </Accordion>
  )
}
