import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { DescriptionType } from '../../types'
import checklistIcon from '../../assets/checklist-icon.png'
import {
  Box,
  Flex,
  Grid,
  Image,
  Text,
  Link as ChakraLink,
} from '@chakra-ui/react'
import { DisplayHTML } from '../common/DisplayHTML'

export function Description({
  data,
}: {
  data: DescriptionType
}): React.ReactElement {
  return (
    <Grid data-testid="description-component" gap="8" pb={12} pt={6}>
      <DisplayHTML data={data.introduction} />
      <Box>
        <Text color='blue.800' fontWeight="semibold" pb="3">
          What you&apos;ll learn
        </Text>
        <Grid
          rowGap={{ base: '3', md: '6' }}
          columnGap={12}
          templateColumns={{ base: '1fr', md: '1fr 1fr' }}
        >
          {data.whatToLearn.map(list => (
            <Flex key={list} gap="2">
              <Image
                src={checklistIcon}
                alt="Checklist Icon"
                mt={{ base: 2, md: 1 }}
                height={{ base: '10px', md: '12px' }}
                width={{ base: '10px', md: '12px' }}
              />
              <Text fontSize={{ base: '14px', md: '16px' }}>{list}</Text>
            </Flex>
          ))}
        </Grid>
      </Box>
      <Grid fontSize={{ base: '14px', md: '16px' }} gap={2}>
        <DisplayHTML data={data.conclusion} />
        <Text>
          What are you waiting for?{' '}
          <ChakraLink
            fontSize={{ base: '14px', md: '16px' }}
            textDecoration="underline solid blue"
            as={RouterLink}
            to="/register"
            letterSpacing="1px"
            color="blue.500"
          >
            Enroll now!
          </ChakraLink>
        </Text>
      </Grid>
    </Grid>
  )
}
