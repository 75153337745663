import React from 'react'
import { TabList, TabPanels, Tab, Tabs, TabPanel } from '@chakra-ui/react'
import { Description } from '../programmes/Description'
import { Curriculum } from '../../types'
import { CourseAccordion } from './CourseAccordion'

const TabsComp = ({ data }: { data: Curriculum }): React.ReactElement => {
  return (
    <Tabs
      shadow="md"
      paddingX={{ base: 4, md: 5 }}
      bg="white"
      borderRadius="md"
    >
      <TabList
        color="gray.600"
        borderBottomWidth="1px"
        borderBottomColor="gray.300"
      >
        <Tab
          data-testid="curriculum-tab"
          _selected={{
            color: 'blue.500',
            borderBottomWidth: '2px',
            borderBottomColor: 'blue.500',
          }}
          py={{ base: 3, md: 4 }}
        >
          Curriculum
        </Tab>
        <Tab
          data-testid="description-tab"
          py={{ base: 3, md: 4 }}
          ml={4}
          _selected={{
            color: 'blue.500',
            borderBottomWidth: '2px',
            borderBottomColor: 'blue.500',
          }}
        >
          Description
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <CourseAccordion data={data.accordions} />
        </TabPanel>
        <TabPanel>
          <Description data={data.description} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export default TabsComp
