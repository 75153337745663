import frontend from '../assets/frontend.jpg'
import webDev from '../assets/webdev.webp'
import dataAnalysis from '../assets/data-analysis.webp'
import qaAutomation from '../assets/qaAuto.webp'
import qaManual from '../assets/qaManual.webp'
import devops from '../assets/devops.webp'
import productDesign from '../assets/product-design.webp'
import blockchain from '../assets/Blockchain.webp'
import { ProgrammeType } from '../types'
import { learningInterests } from './courses'

export const programmes: ProgrammeType[] = [
  {
    title: 'Product Design',
    description: `Learn the fundamentals of product design, including user research, prototyping, and UI/UX design. Master tools like Figma, and more! 🎨
    `,
    learningOutcomes: [
      'User Research 🕵️',
      'Wireframing and Prototyping 🛠️',
      'Design Thinking 🧠',
      'UI/UX Design 🎨',
      'Figma, etc. 🛠️',
      'And much more!',
    ],
    link: '/product/product-design',
    duration: '6 Months',
    image: productDesign,
    key: learningInterests.productDesign,
  },
  {
    title: 'Data Analysis',
    description: `Learn the fundamentals of data analysis, including data cleaning, data visualization, and statistical analysis. Master tools like Excel, Python, SQL, Git, and more! 📊
    `,
    learningOutcomes: [
      'Excel 📊',
      'Python 🐍',
      'SQL 🗄️',
      'Data Visualization 📊',
      'Statistical Analysis 📈',
      'And much more!',
    ],
    link: '/data/data-analysis',
    duration: '6 Months',
    image: dataAnalysis,
    key: learningInterests.dataAnalysis,
  },
  {
    title: 'Frontend Engineering',
    description: `Excel in the core principles of software engineering, delve into ReactJS, and beyond. Craft responsive and secure web interfaces, champion web security, optimize performance, and perfect backend communication.

    `,
    learningOutcomes: [
      'Software Engineering best practices and principles',
      'Advanced Data Structures and Algorithms 🔢',
      'React.js (Hooks, Context, Redux) ⚛️',
      'Next.js',
      'Web Security 🔒',
      'Software application testing 🧪🔍',
      'And much more!',
    ],
    link: '/engineering/frontend',
    duration: '6 Months',
    image: frontend,
    key: learningInterests.frontend,
  },
  {
    title: 'Backend Engineering',
    description: `
    Master JavaScript/Node.js, Python, Ruby, and frameworks like Django, Flask, Ruby on Rails, and NestJS/Express.
    Learn database design, API development, security, scalability, and more. 🚀
        `,
    learningOutcomes: [
      'Software Engineering best practices and principles',
      'Node.js (Express) or Python/Django or Ruby on Rails or C# (.NET)',
      'Database Design and Management 🗄️',
      'Web Security 🔒',
      'And much more!',
    ],
    link: '/engineering/backend',
    duration: '6 Months',
    image: webDev,
    key: learningInterests.backend,
  },
  {
    title: 'Manual QA Engineering',
    description: `
    Learn how to create and execute test cases, identify bugs, and collaborate with development teams to ensure flawless software delivery. 🛠️
        `,
    learningOutcomes: [
      'Understanding QA Basics',
      'Software Development Life Cycle',
      'Software Testing Life Cycle',
      'Basics of Testing',
      'And much more!',
    ],
    link: '/engineering/manual-qa',
    duration: '6 Months',
    image: qaManual,
    key: learningInterests.qaManual,
  },
  {
    title: 'Automation QA Engineering',
    description: `
    Learn how to design, develop, and execute automated test scripts, integrate testing into CI/CD pipelines, and enhance software performance with efficient, scalable testing strategies. 🚀
        `,
    learningOutcomes: [
      'Introduction to Software Testing',
      'Java Basics',
      'Object-Oriented Programming in Java',
      'Advanced Java',
      'And much more!',
    ],
    link: '/engineering/automation-qa',
    duration: '6 Months',
    image: qaAutomation,
    key: learningInterests.qaAutomation,
  },
  {
    title: 'Blockchain Engineering',
    description: `
   Master the essentials of blockchain by learning to design, deploy, and secure decentralized applications (DApps). Gain expertise in blockchain architecture, smart contract development, and integrating blockchain solutions into real-world applications.🚀
        `,
    learningOutcomes: [
      'Introduction to Blochain Architecture',
      'Blockchain Primitives',
      'Ethereum Basics',
      'Solidity Fundamentals',
      'And much more!',
    ],
    link: '/engineering/blockchain',
    duration: '6 Months',
    image: blockchain,
    key: learningInterests.blockchain,
  },
  {
    title: 'DevOps/Cloud Engineering',
    description:
      'Master the essential skills of DevOps and Cloud Engineering by learning to automate workflows, manage infrastructure using Infrastructure as Code (IaC), and deploy applications seamlessly through CI/CD practices.🚀',
    learningOutcomes: [
      'Operating systems',
      'Scripting and Automation',
      'Version control',
      'Virtualization and Containers',
      'And much more!',
    ],
    link: '/engineering/devops',
    duration: '6 Months',
    image: devops,
    key: learningInterests.devOps,
  },
  {
    title: 'Fullstack Engineering',
    description:
      'Learn the essential skills of Fullstack Development by building interactive frontend interfaces, developing robust backend APIs, and managing databases effectively. Deploy applications seamlessly and gain hands-on experience with industry-standard tools and frameworks.🚀',
    learningOutcomes: [
      'Frontend Development with HTML, CSS, and JavaScript',
      'Backend Development using Node.js / Express and Python / Django',
      'Database Management with MongoDB and SQL',
      'API Design and Integration',
      'Version Control with Git and GitHub',
      'Deployment and Hosting on cloud platforms',
      'Security best practices for Fullstack applications',
      'And much more!',
    ],
    link: '/engineering/fullstack',
    duration: '1 Year',
    image: webDev,
    key: learningInterests.fullstack,
  },
]
