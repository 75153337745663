import React from 'react'
import {
  FAQs,
  Mentors,
  Metadata,
  MissionAndVision,
  WhoWeAre,
} from '../components'
import { mentors } from '../constants/mentors'

export const AboutPage: React.FC = () => {
  return (
    <>
      <Metadata
        title="About Us | Dot Campus"
        description="Learn more about Dot Campus, our mission and vision."
      />
      <WhoWeAre />
      <MissionAndVision />
      <Mentors noOfMentors={mentors.length} />
      <FAQs />
    </>
  )
}
