import { learningInterests } from './courses'

export const learningInterestChoices = [
  { label: '', value: '' },
  {
    label: 'Data Analysis',
    value: learningInterests.dataAnalysis,
  },
  {
    label: 'Product Design',
    value: learningInterests.productDesign,
  },
  {
    label: 'Frontend Engineering',
    value: learningInterests.frontend,
  },
  {
    label: 'Backend Engineering',
    value: learningInterests.backend,
  },
  {
    label: 'Fullstack Engineering',
    value: learningInterests.fullstack,
  },
  {
    label: 'Manual QA Engineering',
    value: learningInterests.qaManual,
  },
  {
    label: 'Automation QA Engineering',
    value: learningInterests.qaAutomation,
  },
  {
    label: 'Blockchain',
    value: learningInterests.blockchain,
  },
  {
    label: 'DevOps/Cloud Engineering',
    value: learningInterests.devOps,
  },
  { label: 'Web Development', value: learningInterests.web },
  {
    label: 'Data Analysis with Excel',
    value: learningInterests.excelDataAnalysis,
  },
  {
    label: 'Data Analysis with Python',
    value: learningInterests.pythonDataAnalysis,
  },
  {
    label: 'React Frontend Dev',
    value: learningInterests.reactFrontend,
  },
  {
    label: 'Node.js Backend Dev',
    value: learningInterests.nodeBackend,
  },
  {
    label: 'Python Backend Dev',
    value: learningInterests.pythonBackend,
  },
  {
    label: 'Java Backend Dev',
    value: learningInterests.javaBackend,
  },
  {
    label: 'Software Engineering Essentials',
    value: learningInterests.softwareEngineeringEssentials,
  },
]
