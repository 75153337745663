import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import React from 'react'
import { APPLICATION_MAX_WIDTH } from '../../constants'
import { missionAndVision } from '../../constants/about'

export const MissionAndVision = (): React.ReactElement => {
  return (
    <Flex
      flexDirection={{ base: 'column', lg: 'row' }}
      backgroundColor="blue.900"
      textAlign="center"
      width="100%"
    >
      {missionAndVision.map(item => (
        <Box
          key={item.title}
          padding="5"
          my="7"
          width="100%"
          maxWidth={APPLICATION_MAX_WIDTH}
          marginX="auto"
          paddingX="12"
        >
          <Heading as="h2" fontWeight="bold" color="white" marginBottom="5">
            {item.title}
          </Heading>
          <Text as="p" fontSize={{ base: 'md', md: 'lg' }} color="white">
            {item.content}
          </Text>
        </Box>
      ))}
    </Flex>
  )
}
